import React from 'react'
import UploadDialog from '../components/Dialogs/UploadDialog'

const Upload = () => {
  return (
    <div className='h-screen p-3'>
      <UploadDialog />
    </div>
  )
}

export default Upload