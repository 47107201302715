import React from "react";

const Footer = () => {
  return (
    <div className="border-white border-t-[0.1rem] w-full mt-3 p-3 text-center font-sans text-customGrey ">
      Adira AI is a Proprietory Legal Based Generative AI developed by CLAW
      Legal Tech
    </div>
  );
};

export default Footer;
